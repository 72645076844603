.tik-tok-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  pointer-events: none;
  user-select: none;
}

.tik-tok-background {
  background: rgba(0, 0, 0, 0.5);
}

.tik-tok-container {
  background: rgba(217, 217, 217, 0.3);
  position: absolute;
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.tik-tok-label-policy {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #ca6702;
}

.tik-tok-modal {
  width: 705px;
  left: 271px;
  top: 23px;
  border: 1px solid #dee2e6;
  padding: 24px;

  background: #ffffff;
  border-radius: 16px;
}

.tik-tok-method {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: fit-content;
  gap: 32px;
  margin-top: 24px;
}

@media only screen and (max-width: 650px) {
  .tik-tok-method {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.tik-tok-error {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 12px;
  color: red;
  text-align: center;
}

.tik-tok-method span {
  border-color: #459396;
  width: 30px;
  height: 30px;
}

.tik-tok-upload {
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tik-tok-upload label {
  margin: 0 auto !important;
  width: 58% !important;
  border: 2px dashed #026b7b !important;
  height: 124px !important;
  padding: 8px 32px 8px 32px !important;
}

.tik-tok-upload label svg path {
  fill: #026b7b !important;
  stroke: #026b7b !important;
}

.tik-tok-done {
  outline: none;
  border: none;
  background: linear-gradient(180deg, #9ecfbd 0%, #459396 100%);
  border-radius: 10px;
  width: 230px;
  height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-inline: auto;
}

.tik-tok-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.policy-content {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #9c9992;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.content-1 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.creator-img {
  margin: 64px 0px;
  display: flex;
  justify-content: center;
}

.next-btn {
  width: 260px;
  height: 51px;
  background: #bb3e03;
  border-radius: 40px;
  left: 169px;
  top: 571px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.next-btn span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  text-transform: uppercase;
}

.next-btn-container {
  display: flex;
  justify-content: center;
  cursor: pointer;
  gap: 12px;
}

.close-icon {
  cursor: pointer;
}

.next-btn-sm {
  width: 224px;
  height: 51px;
  background: #bb3e03;
  border-radius: 40px;
  left: 47.65%;
  right: 24.88%;
  top: 89.65%;
  bottom: 4.39%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.next-btn-sm span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  text-transform: uppercase;
}

.back-btn-sm {
  left: 24.88%;
  right: 53.84%;
  top: 89.65%;
  bottom: 4.39%;
  width: 224px;
  height: 51px;
  background: #ee9b00;
  border-radius: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.back-btn-sm span {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  text-transform: uppercase;
}

.authorization-code-input {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 20px 0px;
  flex-direction: column;
}

.authorization-code-input input {
  width: 100%;
  color: #9c9992;
}

@media only screen and (max-width: 650px) {
  .tik-tok-modal {
    width: auto;
  }
}

@media only screen and (max-width: 525px) {
  .tik-tok-modal {
    width: 359px;
    max-height: 650px;
    padding: 34px 29px 16px;
  }

  .tik-tok-header {
    margin: 0 0 20px;
    align-items: flex-start;
  }

  .creator-img {
    display: none;
  }

  .next-btn-container {
    margin-top: 20px;
  }

  .back-btn-sm {
    display: none;
  }

  .next-btn-sm {
    width: 469px;
  }

  .authorization-code-input {
    margin: 40px 0 22px;
    align-items: flex-end;
  }

  .authorization-code-input input {
    width: 300px;
  }
}

.tiktok-alert-message {
  pointer-events: all;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

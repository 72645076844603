.custom-tooltip-brand-activation ul {
    margin: 0;
    padding-left: 1rem;
    list-style-type: disc;
}

.custom-tooltip-brand-activation ul li {
    margin: 5px 0;
}

.custom-tooltip-brand-activation .react-tooltip-arrow {
    color: #ff872f;
}

.custom-tooltip-brand-activation {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    line-height: 1.4;
    width: 100% !important;
    min-width: 250px !important;
    max-width: 350px !important;
    background: white !important;
    height: fit-content !important;
    border: 1px solid orange !important;
    border-radius: 8px !important;
    color: black !important;
    opacity: 1 !important;
    display: flex !important;
    flex-wrap: wrap !important;
    overflow: visible !important;
    z-index: 999 !important;
}


.custom-tooltip-brand-activation.left {
    margin-left: 1px !important;
}

.custom-tooltip-brand-activation div {
    border-right: 1px solid orange !important;
    border-bottom: 1px solid orange !important;
}

@media only screen and (max-width: 500px) {
    .custom-tooltip-brand-activation {
        max-width: 300px !important;
    }
}

@media only screen and (max-width: 400px) {
    .custom-tooltip-brand-activation {
        max-width: 230px !important;
    }
}
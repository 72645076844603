.brand-table-container {
  box-sizing: border-box;
  width: 100%;
  min-height: 308px;
  left: calc(50% - 1127px / 2 + 89.5px);
  top: 196px;
  background: #ffffff;
  border: 1px solid #9fd0bd;
  border-radius: 6px;
  padding: 15px 15px 10px;
}

.brand-table-wrapper {
  height: 308px;
  position: relative;
}

.creative-table-container {
  margin-bottom: 10px;
}

.dashboard-label {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #1c1c1c;
}

.brand-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brand-table-label {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #1c1c1c;
  margin-bottom: 8px;
}

.brand-profile-container {
  display: flex;
  flex-direction: column;
}

.brand-name {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #001219;
  margin-bottom: 10px;
}

.brand-description {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #001219;
  margin-bottom: 15px;
}

.brand-profile-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;
  cursor: pointer;
}

.brand-profile-btn {
  background: #ffffff;
  border: 1px solid #9fd0bd;
  border-radius: 10px;
  height: 34px;
  width: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.brand-profile-text {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #001219;
}

.dashboard-campaign-section {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.dashboard-campaign-brief-wrapper {
  width: 100%;
}

.dashboard-campaign-brief-container {
  box-sizing: border-box;
  width: 100%;
  height: 251px;
  left: calc(50% - 558.5px / 2 - 194.75px);
  top: 391px;
  background: #ffffff;
  border: 1px solid #9fd0bd;
  border-radius: 6px;
  padding: 15px 15px 10px;
}

.dashboard-campaign-table-wrapper {
  height: 224px;
  position: relative;
}

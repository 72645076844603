.footer {
    color: #fff;
    padding: 10px 100px 15px 100px;
    background-color: #202020;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 100px;
    background-color: #202020;
    width: 100%;
    box-sizing: border-box;
}

.footer-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.footer-email {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    font-family: "Inter", sans-serif;
}

.footer-social {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.social-icon {
    width: 27px;
    height: 27px;
    object-fit: contain;
}

.social-icon-whatsapp {
    margin-top: 7px;
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.popup-container {
    position: relative;
    display: inline-block;
}

.popup {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    bottom: 40px;
    left: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
    font-family: 'Inter', sans-serif;
    animation: bounceIn 0.5s ease;
}

.popup::after {
    content: '';
    position: absolute;
    bottom: -22px;
    left: 0px;
    border-width: 12px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

.popup a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 8px 10px;
    border-radius: 5px;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.popup a:hover {
    background-color: #444;
    color: #fff;
}

.social-icon-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    70% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}

.footer-right {
    flex: 0.5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
}

.footer-image {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    object-fit: cover;
}

.footer-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 20px 100px;
    gap: 40px;
    background-color: #202020;
    width: 100%;
    box-sizing: border-box;
}

.footer-logo {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
}

.footer-logo img {
    width: 260px;
    height: auto;
}

.footer-column {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    margin-left: 80px;
    margin-top: 20px;
    background-color: transparent;
    box-sizing: border-box;
}

.footer-idividual-column {
    flex: 1;
    min-width: 200px;
    background-color: transparent;
    box-sizing: border-box;
    padding: 10px;
}

.footer-idividual-column h4 {
    margin-bottom: 40px;
    font-size: 21px;
    font-family: "Inter", sans-serif;
    letter-spacing: -0.04em;
    text-align: left;
    font-weight: 400;
}

.footer-column ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.footer-column li {
    margin-bottom: 10px;
    padding: 0px;
    font-family: "Inter", sans-serif;
    letter-spacing: -0.04em;
    text-align: left;
    box-sizing: border-box;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.footer-link {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
}

.footer-link:hover {
    color: #FF872F;
    transform: scale(1.05);
}

.footer-idividual-column:last-child {
    align-items: flex-start;
}

.footer-copyright {
    text-align: left;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    padding-top: 10px;
    padding-left: 100px;
    padding-bottom: 20px;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .footer {
        padding: 20px;
        width: 100%;
        /* Ensure full width */
        margin: 0;
        box-sizing: border-box;
    }

    .footer-top {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 10px;
        /* Reduce side padding */
        gap: 20px;
    }

    .footer-left {
        align-items: flex-start;
        text-align: left;
        gap: 15px;
    }

    .footer-email {
        font-size: 1.5rem;
        text-align: left;
    }

    .footer-social {
        justify-content: flex-start;
    }

    .footer-right {
        margin-top: 20px;
        padding: 0 10px;
        /* Adjust side padding */
        max-width: 100%;
        text-align: left;
    }

    .footer-image {
        width: 100%;
        max-width: 200px;
    }

    .footer-bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 10px;
        /* Consistent side padding */
        gap: 20px;
        text-align: left;
    }

    .footer-logo {
        text-align: left;
    }

    .footer-column {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-left: 0;
        width: 100%;
        text-align: left;
    }

    .footer-idividual-column {
        flex: 1;
        min-width: calc(50% - 10px);
        /* Adjust column width */
        text-align: left;
    }

    .footer-idividual-column:nth-child(2) {
        order: 1;
    }

    .footer-idividual-column:nth-child(1) {
        order: 2;
    }

    .footer-idividual-column:nth-child(3) {
        order: 3;
    }

    .footer-idividual-column h4 {
        margin-bottom: 20px;
        /* Adjust heading spacing */
        font-size: 18px;
    }

    .footer-column ul {
        text-align: left;
    }

    .footer-column li {
        margin-bottom: 15px;
        text-align: left;
    }

    .footer-copyright {
        text-align: left;
        padding: 10px 10px;
        /* Match side padding */
    }

    .footer-logo-mobile {
        text-align: left;
        margin: 20px 10px;
    }

    .footer-logo-mobile img {
        width: 150px;
        height: auto;
    }
}
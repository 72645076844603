.admin-dashboard-cards {
  display: flex;
  align-items: center;
  gap: 10px;
}

.admin-dashboard-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #041416;
}

.admin-dashboard-card-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.admin-table-container {
  margin-top: 10px;
}

.admin-panel {
  width: 100%;
}

.creator-payments-container {
  padding: 15px;
  background: #ffffff;
  border-radius: 6px;
  height: 308px;
  width: auto;
}

.creator-payments {
  font-family: 'Raleway';
  font-style: normal;
  line-height: 20px;
  color: #0a9396;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px;
  margin-left: 10px;
}

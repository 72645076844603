.creator-notifications-container {
  box-sizing: border-box;
  width: 100%;
  height: 251px;
  left: calc(50% - 558.5px / 2 - 194.75px);
  top: 514px;
  background: #ffffff;
  border: 1px solid #9fd0bd;
  border-radius: 6px;
  padding: 15px;
  overflow: auto;
}

.notifications-container {
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  height: 219px;
  overflow-y: auto;
}

.creative-notification-icon-and-text {
  display: flex;
  gap: 8px;
  align-items: start;
}

.creator-notifications-label {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #1c1c1c;
}

.creative-notification-container {
  margin-top: 12px;
}

.notifiation-container {
  margin-left: 29px;
  margin-top: -5px;
}

.notification-label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9C9992;
}

.notification-timeline {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #a1a1a1;
}

@media only screen and (max-width: 768px) {
  .creator-notifications-container,
  .best-practices-container {
    height: 124px;
  }

  .best-practices-wrapper {
    height: 92px;
  }
}
